import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import { useSharedState } from 'context/state.context'

import styles from './styles'

const useStyles = makeStyles(styles)

const LoginModal = memo(() => {
  const classes = useStyles()
  const { t } = useTranslation('loginModal')
  const [{ showLoginModal }, { cancelLogin, cloudWalletLogin, ualLogin }] =
    useSharedState()

  return (
    <Modal open={showLoginModal} onClose={cancelLogin} className={classes.root}>
      <Card className={classes.cardWrapper}>
        <CardContent>
          <Typography className={classes.modalTitle}>
            {t('loginWith')}
          </Typography>
          <Box className={classes.options}>
            <Button className={classes.buttonWallet} onClick={cloudWalletLogin}>
              {t('cloudWallet')}
            </Button>
            <Button className={classes.buttonWallet} onClick={ualLogin}>
              {t('otherWallets')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  )
})

LoginModal.displayName = 'LoginModal'

LoginModal.propTypes = {}

export default LoginModal
