import { wax } from 'utils'
import { mainConfig } from 'config'

export const getAssetsCollection = async (accountName, limit = 1000) => {
  const { rows, more } = await wax.rpc.get_table_rows({
    json: true,
    code: mainConfig.contractClaim,
    scope: accountName,
    table: 'sassets',
    reverse: true,
    limit
  })

  const collection = (rows || [])
    .filter(({ category }) => category === 'k2')
    .map(asset => {
      const iData = JSON.parse(asset?.idata.length ? asset?.idata : '{}')
      const mData = JSON.parse(asset?.mdata.length ? asset?.mdata : '{}')

      return { ...asset, assetData: { ...iData, ...mData } }
    })

  return { collection, hasMore: more }
}
