import {
  wax,
  eosApi,
  formatResource,
  formatWithThousandSeparator,
  atomicAssetsApi
} from 'utils'
import { mainConfig } from '../config'

export const getPackInfo = async user => {
  try {
    let myPacks = {}
    const userAssets = await atomicAssetsApi.getAssets(
      {
        collection_name: mainConfig.collectionName,
        owner: user,
        sort: 'desc'
      },
      1,
      100
    )

    myPacks = userAssets.length
      ? userAssets.reduce(
          (acc, curr) => {
            if (curr.template.immutable_data.nfts_inside === 2) {
              return {
                ...acc,
                regular: [...acc.regular, curr.asset_id]
              }
            }

            return { ...acc, mega: [...acc.mega, curr.asset_id] }
          },
          { regular: [], mega: [] }
        )
      : null

    return myPacks
  } catch (error) {
    console.error(error)
  }
}

export const getUserInfo = async (user, useWax) => {
  let tokenInfo = '0'
  let myPacks = {}
  const currency = useWax ? 'WAX' : 'EOS'
  const userInfo = useWax
    ? await wax.rpc.get_account(user)
    : await eosApi.getAccount(user)

  try {
    tokenInfo = useWax
      ? await wax.rpc.get_currency_balance('kolobokbucks', user, 'KBUCKS')
      : await eosApi.getCurrencyBalance('kolobokbucks', user, 'KBUCKS')
  } catch (error) {
    console.error(error)
  }

  const tokenBalance = (tokenInfo[0] || '').split(' ')
  const balance = (userInfo?.core_liquid_balance || '').split(' ')
  myPacks = await getPackInfo(user)

  return {
    packs: myPacks,
    token: formatWithThousandSeparator(tokenBalance[0] || 0, 0, true),
    tokenCurrency: 'KBUCKS',
    balance: formatWithThousandSeparator(balance[0] || 0, 0, true),
    currency: balance[1] || currency,
    ram: `${formatResource(userInfo?.ram_usage || 0, 2)} KB / ${formatResource(
      userInfo?.ram_quota || 0,
      2
    )} KB`,
    cpu: `${formatResource(
      userInfo?.cpu_limit?.used || 0,
      2,
      true
    )} ms / ${formatResource(userInfo?.cpu_limit?.available || 0, 2, true)} ms`
  }
}
