export default theme => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  buttonWallet: {
    fontSize: '20px !important'
  },
  cardWrapper: {
    minWidth: 304,
    backgroundColor: '#0e1d30 !important',
    borderRadius: `${theme.spacing(1)} !important`
  },
  modalTitle: {
    color: theme.palette.common.white,
    fontSize: '24px !important'
  }
})
