const breakpoints = {
  values: {
    xs: 0,
    xss: 375,
    sm: 600,
    smm: 800,
    md: 1024,
    mdd: 1224,
    lg: 1440,
    xl: 1920
  }
}

export default breakpoints
