import React, { lazy } from 'react'

import { Home as HomeIcon } from 'react-feather'

const Home = lazy(() => import('./Home'))
const PackOpening = lazy(() => import('./PackOpening'))
const YourCollection = lazy(() => import('./YourCollection'))
const Page404 = lazy(() => import('./Route404'))

const routes = [
  {
    name: 'home',
    icon: <HomeIcon />,
    component: Home,
    path: '/',
    exact: true
  },
  {
    name: 'packOpening',
    icon: <HomeIcon />,
    component: PackOpening,
    path: '/open',
    exact: true
  },
  {
    name: 'yourCollection',
    icon: <HomeIcon />,
    component: YourCollection,
    path: '/my-collection',
    exact: true
  },
  {
    component: Page404
  }
]

export default role => {
  const routesForRole = routes.filter(
    route => !route.roles || route.roles.includes(role)
  )

  return {
    sidebar: routesForRole.filter(route => !!route.name),
    browser: routesForRole
      .reduce(
        (routes, route) => [
          ...routes,
          ...(route.childrens ? route.childrens : [route])
        ],
        []
      )
      .filter(route => !!route.component)
  }
}
