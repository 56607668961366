/* eslint-disable no-undef */
import { ExplorerApi } from 'atomicassets'

import { mainConfig } from '../config'

export const atomicAssetsApi = new ExplorerApi(
  mainConfig.apiWaxAtomicAssets,
  mainConfig.contractUnpack,
  { fetch }
)

export default atomicAssetsApi
