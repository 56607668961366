import React, { memo, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import { useSharedState } from 'context/state.context'
import { mainConfig } from 'config'
import PageTitle from '../PageTitle'

import UserButton from './UserButton'
import MenuButton from './MenuButton'
import styles from './styles'

const useStyles = makeStyles(styles)

const Header = memo(({ showHeader }) => {
  const classes = useStyles()
  const { t } = useTranslation('routes')
  const history = useHistory()
  const location = useLocation()
  const [state, { loginBothWallet, logoutBothWallet }] = useSharedState()
  const [isHomePath, setIsHomePath] = useState(false)

  const handleLogin = () => {
    loginBothWallet()
  }

  const handleSignOut = () => {
    logoutBothWallet()
    history.push('/')
  }

  useEffect(() => {
    setIsHomePath(location.pathname === '/')
  }, [location.pathname])

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.notVisibleHeader]: isHomePath && !showHeader
      })}
      position="sticky"
    >
      <PageTitle title={t(`${location.pathname}>title`, mainConfig.title)} />
      <Toolbar className={classes.toolbar}>
        <MenuButton classes={classes} user={state.user} />
        <img
          className={clsx(classes.headerLogoK2, {
            [classes.displayNone]: isHomePath && !showHeader
          })}
          alt="Logo"
          src="/k2header.svg"
        />
        <Box className={classes.desktopSection}>
          <UserButton
            user={state.user}
            classes={classes}
            onLogin={handleLogin}
            onSignOut={handleSignOut}
          />
        </Box>
      </Toolbar>
    </AppBar>
  )
})

Header.displayName = 'Header'

Header.propTypes = {
  showHeader: PropTypes.bool
}

export default Header
