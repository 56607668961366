import { wax, formatWithThousandSeparator } from 'utils'

export const getPackValue = price => {
  const valueSplited = price.split(' ')
  const value = formatWithThousandSeparator(parseFloat(valueSplited[0]), 0)

  return {
    currency: valueSplited[1] || '',
    value: parseFloat(value),
    formatedValue: `${value}${valueSplited[1] || ''}`
  }
}

export const getPacks = async () => {
  const { rows } = await wax.rpc.get_table_rows({
    json: true,
    code: 'koloboksales',
    scope: 'koloboksales',
    table: 'sales',
    reverse: false,
    show_payer: false
  })

  const activeRow = (rows.length ? rows : []).filter(row => row.active === 1)

  if (!activeRow.length)
    return {
      mega: false,
      regular: false
    }

  const { packs, prices, types, ...salesData } = activeRow[0]
  const packsData = types.reduce(
    (acc, current) => {
      const type = (current.value || '').toLowerCase()
      const price = (prices || []).find(
        priceItem => priceItem.key === current.key
      )
      const pack = (packs || []).find(packItem => packItem.key === current.key)
      const values = getPackValue(price?.value?.quantity || '')

      return {
        ...acc,
        [type]: {
          key: current.key,
          price,
          pack,
          isValid: !!price && !!pack,
          ...values
        }
      }
    },
    {
      regular: null,
      mega: null
    }
  )

  return { ...packsData, ...salesData }
}
