import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import MenuIcon from '@mui/icons-material/Menu'

const MenuButton = memo(({ classes }) => {
  const [userAnchorEl, setLanguageAnchorEl] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation('menu')

  const handleUserMenuOpen = event => {
    setLanguageAnchorEl(event.currentTarget)
  }

  const handleUserMenuCloseModal = () => {
    setLanguageAnchorEl(null)
  }

  const handleOnClick = path => {
    if (location.pathname !== path) {
      history.push(path)
    }

    setLanguageAnchorEl(null)
  }

  const handleOnRedirect = () => {
    window.open('https://wax.kolobok.io')
    setLanguageAnchorEl(null)
  }

  return (
    <>
      <Button
        className={classes.roundedBtn}
        onClick={handleUserMenuOpen}
        variant="outlined"
        startIcon={<MenuIcon />}
      >
        MENU
      </Button>
      <Menu
        keepMounted
        anchorEl={userAnchorEl}
        open={!!userAnchorEl}
        onClose={handleUserMenuCloseModal}
        classes={{ paper: classes.menuUser }}
      >
        <div className={classes.userInfo}>
          <div onClick={() => handleOnClick('/')}>{t('home')}</div>
          <div onClick={() => handleOnClick('/open')}>{t('packOpening')}</div>
          <div onClick={() => handleOnClick('/my-collection')}>
            {t('yourCollection')}
          </div>
          <div onClick={handleOnRedirect}>{t('about')}</div>
        </div>
      </Menu>
    </>
  )
})

MenuButton.displayName = 'MenuButton'

MenuButton.propTypes = {
  classes: PropTypes.any
}

export default MenuButton
