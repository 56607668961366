const getActionObj = (actor, ual, contract, actionName, data) => {
  const permission = ual?.activeUser?.requestPermission || 'active'

  return {
    actions: [
      {
        authorization: [
          {
            actor,
            permission
          }
        ],
        account: contract,
        name: actionName,
        data
      }
    ]
  }
}

export const getTrxActions = (state, contract, inputValue, type) => {
  const { urlParams, user, ual, data } = state
  const memo = `${
    urlParams?.source.length ? `#source${urlParams.source}` : ''
  }${urlParams?.utmSource.length ? `#utm_source${urlParams.utmSource}` : ''}${
    urlParams?.referral.length ? `#referral${urlParams.referral}` : ''
  }`

  return getActionObj(user.accountName, ual, contract, 'transfer', {
    from: user.accountName,
    to: 'koloboksales',
    quantity: `${(inputValue * data[type].value).toFixed(8)} ${
      data[type].currency
    }`,
    memo: `sale_id:${data.sale_id};${data[type].key}${memo}`
  })
}

export const getTrxUnPack = ({ user, ual }, contract, assetId) => {
  return getActionObj(user.accountName, ual, contract, 'transfer', {
    from: user.accountName,
    to: 'kolobokpacks',
    asset_ids: [assetId],
    memo: 'unpack'
  })
}

export const getTrxClaim = ({ user, ual }, contract, assetids) => {
  return getActionObj(user.accountName, ual, contract, 'claim', {
    claimer: user.accountName,
    assetids
  })
}
