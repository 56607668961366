const formatParams = (value, maxLength) => {
  const clearValue = value.replace('#', '').replace('"', '').replace("'", '')

  return clearValue.substring(0, maxLength)
}

const validateData = (value = '', name, params, maxLength) => {
  const urlValue = formatParams(params.get(name) || '', maxLength)

  if (urlValue?.length && value !== urlValue) return urlValue

  if (value?.length) return value

  const item = window.localStorage.getItem(name)

  if (item?.length) return item

  return ''
}

export const getUrlParams = ({ urlParams }) => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const source = validateData(urlParams.source, 'source', params, 15)
  const utmSource = validateData(urlParams.utmSource, 'utm_source', params, 15)
  const referral = validateData(urlParams.referral, 'referral', params, 12)

  window.localStorage.setItem('source', source)
  window.localStorage.setItem('utm_source', utmSource)
  window.localStorage.setItem('referral', referral)

  return {
    source,
    utmSource,
    referral
  }
}
