import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import AccountIcon from '@mui/icons-material/AccountCircle'

const UserButton = memo(({ user, classes, onSignOut, onLogin }) => {
  const [userAnchorEl, setLanguageAnchorEl] = useState(null)
  const { t } = useTranslation('common')

  const handleUserMenuOpen = event => {
    setLanguageAnchorEl(event.currentTarget)
  }

  const handleUserMenuClose = () => {
    setLanguageAnchorEl(null)
    onSignOut()
  }

  const handleUserMenuCloseModal = () => {
    setLanguageAnchorEl(null)
  }

  return (
    <>
      {user ? (
        <>
          <Button
            className={classes.roundedBtn}
            onClick={handleUserMenuOpen}
            variant="outlined"
            startIcon={<AccountIcon />}
          >
            {user.accountName}
          </Button>
          <Menu
            keepMounted
            anchorEl={userAnchorEl}
            open={!!userAnchorEl}
            onClose={handleUserMenuCloseModal}
            classes={{ paper: classes.menuUser }}
          >
            <div className={classes.userInfo}>
              <span className={classes.userTitle}>{t('balance')}</span>
              <span className={classes.userSpan}>
                <strong className={classes.strongRight}>{user.token}</strong>
                {user.tokenCurrency}
              </span>
              <span className={classes.userSpan}>
                <strong className={classes.strongRight}>{user.balance}</strong>
                {user.currency}
              </span>
              <span className={classes.userTitle}>{t('resource')}</span>
              <span className={classes.userSpan}>
                RAM
                <strong className={classes.strongLeft}>{user.ram}</strong>
              </span>
              <span className={classes.userSpan}>
                CPU <strong className={classes.strongLeft}>{user.cpu}</strong>
              </span>

              <Button
                className={classes.logoutBtn}
                onClick={handleUserMenuClose}
                variant="outlined"
              >
                {t('signOut')}
              </Button>
            </div>
          </Menu>
        </>
      ) : (
        <Button
          variant="outlined"
          startIcon={<AccountIcon />}
          className={classes.roundedBtn}
          onClick={onLogin}
        >
          {t('login')}
        </Button>
      )}
    </>
  )
})

UserButton.displayName = 'UserButton'

UserButton.propTypes = {
  user: PropTypes.any,
  classes: PropTypes.any,
  onSignOut: PropTypes.func,
  onLogin: PropTypes.func
}

export default UserButton
