import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import Sidebar from 'components/Sidebar'
import Footer from 'components/Footer'
import Header from 'components/Header'
import LoginModal from 'components/LoginModal'
import Message from 'components/Message'
import { useSharedState } from 'context/state.context'

import styles from './styles'

const drawerWidth = 260
const useStyles = makeStyles(styles)

const Dashboard = ({ children, routes }) => {
  const classes = useStyles()
  const location = useLocation()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [state, { setShowHeader }] = useSharedState()
  const mainContainer = useRef(null)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleScroll = e => {
    if (e.target.scrollTop > 120) {
      !state.showHeader && setShowHeader(true)
    } else {
      state.showHeader && setShowHeader(false)
    }
  }

  useEffect(() => {
    if (mainContainer.current) {
      mainContainer.current.scrollTop = 0
    }
  }, [location.pathname])

  return (
    <Box className={classes.root}>
      <Box className={classes.drawer}>
        <Sidebar
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          routes={routes}
        />
      </Box>
      <Box className={classes.mainContent}>
        <Header
          onDrawerToggle={handleDrawerToggle}
          showHeader={state.showHeader}
        />
        <Box
          onScroll={handleScroll}
          className={classes.childContent}
          ref={mainContainer}
        >
          {children}
          <Footer />
        </Box>
        <Message />
        <LoginModal />
      </Box>
    </Box>
  )
}

Dashboard.propTypes = {
  children: PropTypes.node,
  routes: PropTypes.array
}

export default Dashboard
