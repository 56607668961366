import { wax, getTrxUnPack, getTrxClaim } from 'utils'
import { mainConfig } from 'config'

const getSimpleAssets = async ({ state, type }) => {
  const { rows: rowsFilterByUser } = await wax.rpc.get_table_rows({
    json: true,
    code: mainConfig.contractClaim,
    scope: mainConfig.contractClaim,
    table: 'offers',
    index_position: 3,
    lower_bound: state.user.accountName,
    upper_bound: state.user.accountName,
    limit: 1000,
    key_type: 'name',
    show_payer: false
  })

  rowsFilterByUser.sort((a, b) => {
    const bDate = new Date(b.cdate * 1000)
    const aDate = new Date(a.cdate * 1000)
    return bDate - aDate
  })

  let lastNFTs

  if (type === 'regular') lastNFTs = rowsFilterByUser.slice(0, 2)
  else lastNFTs = rowsFilterByUser.slice(0, 8)

  const lastAssetCDate = new Date(lastNFTs[0].cdate * 1000)
  const day = lastAssetCDate.getDate()
  const month = lastAssetCDate.getMonth() + 1
  const year = lastAssetCDate.getFullYear()
  const today = new Date()
  const dayToday = today.getDate()
  const monthToday = today.getMonth() + 1
  const yearToday = today.getFullYear()

  if (`${day}/${month}/${year}` !== `${dayToday}/${monthToday}/${yearToday}`)
    return false

  for (let index = 0; index < lastNFTs.length; index++) {
    if (lastNFTs[0].cdate !== lastNFTs[index].cdate) return false
  }

  return lastNFTs
}

export const signTransaction = async (state, transaction) => {
  if (state.user.useWax) {
    return await wax.api.transact(transaction, {
      blocksBehind: 3,
      expireSeconds: 120,
      broadcast: true
    })
  }

  return await state.ual.activeUser.signTransaction(transaction, {
    blocksBehind: 3,
    expireSeconds: 120,
    broadcast: true
  })
}

export const signUnPackTransaction = async (
  state,
  contractUnpack,
  assetId,
  type,
  updatePackAmount
) => {
  const transaction = getTrxUnPack(state, contractUnpack, assetId)

  await signTransaction(state, transaction)

  const updatePackAmountDelay = new Promise(resolve => {
    setTimeout(() => {
      updatePackAmount(assetId, type)
      resolve('done')
    }, 600)
  })

  await Promise.all([updatePackAmountDelay])

  const lastUnPackAsset = await getSimpleAssets({ state, type })

  if (!lastUnPackAsset) return lastUnPackAsset

  const assetsInfo = await Promise.all(
    (lastUnPackAsset || []).map(async ({ assetid }) => {
      const { rows } = await wax.rpc.get_table_rows({
        json: true,
        code: mainConfig.contractClaim,
        scope: 'kolobokpacks',
        table: 'sassets',
        lower_bound: assetid,
        limit: 1
      })

      if (rows.length && rows[0].id === assetid.toString()) {
        const iData = JSON.parse(rows[0]?.idata.length ? rows[0]?.idata : '{}')
        const mData = JSON.parse(rows[0]?.mdata.length ? rows[0]?.mdata : '{}')

        return { ...rows[0], assetData: { ...iData, ...mData } }
      }

      return null
    })
  )

  return assetsInfo
}

export const signClaimTransaction = async (state, contractClaim, assetId) => {
  const transaction = getTrxClaim(state, contractClaim, assetId)
  const result = await signTransaction(state, transaction)

  return result
}
