export const appVersion = process.env.REACT_APP_TAG || 'v1.0'
export const name = process.env.REACT_APP_NAME
export const contract = process.env.REACT_APP_CONTRACT_BUY || 'eosio.token'
export const contractUnpack =
  process.env.REACT_APP_CONTRACT_UNPACK || 'atomicassets'
export const contractClaim =
  process.env.REACT_APP_CONTRACT_CLAIM || 'simpleassets'
export const title = process.env.REACT_APP_TITLE
export const logo = process.env.REACT_APP_LOGO
export const waxBloksUrl =
  process.env.REACT_APP_WAX_BLOKS_URL || 'https://wax.bloks.io/'
export const apiMailer =
  process.env.REACT_APP_MAILER_URL || 'https://api.subscribe.kolobok.io/'
export const apiWaxAtomicAssets =
  process.env.REACT_APP_ATOMIC_ASSETS_URL ||
  'https://wax-aa.eu.eosamsterdam.net'
export const footerLinks = JSON.parse(
  process.env.REACT_APP_FOOTER_LINKS || '[]'
)
export const burstAnimation = {
  effect1: {
    size: 1000,
    bubles: true,
    bublesCount: 200,
    bublesColor: ['#cc2d75', '#FFFFFF'],
    bublesRadius: 20,
    bublesDelay: 100,
    bublesDuration: 500,
    bublesEasing: 'easeOutCirc',
    circle: true,
    circleColor: ['#FFFFFF'],
    circleDelay: 100,
    circleDuration: 500,
    circleEasing: 'easeOutCubic'
  },
  effect2: {
    size: 100,
    lines: true,
    lineCount: 10,
    lineWidth: 2,
    lineColor: ['#FFFFFF'],
    lineDelay: 0,
    lineDuration: 300,
    lineEasing: 'easeOutSine'
  }
}
export const referralUrl = 'https://k2packs.kolobok.io/?referral='
export const collectionName = process.env.REACT_APP_COLLECTION_NAME
