export const formatWithThousandSeparator = (
  value,
  precision,
  useSpace = false
) => {
  if (!value || isNaN(value)) {
    return value
  }

  let newValue = parseFloat(value)

  if (precision >= 0) {
    newValue = newValue.toFixed(precision)
  }

  if (useSpace) {
    return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
