import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { wax, getUserInfo } from 'utils'

const SharedStateContext = React.createContext()
const initialValue = {
  showLoginModal: false,
  useDarkMode: false,
  user: null,
  showHeader: false,
  data: { mega: false, regular: false },
  urlParams: {
    source: '',
    utmSource: '',
    referral: ''
  }
}

const sharedStateReducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      return {
        ...state,
        ...action.payload
      }
    }

    case 'showHeader': {
      return {
        ...state,
        showHeader: action.showHeader
      }
    }

    case 'showMessage':
      return {
        ...state,
        message: action.payload
      }

    case 'hideMessage':
      return {
        ...state,
        message: null
      }

    case 'setUser':
      return {
        ...state,
        user: action.user,
        showLoginModal: false
      }

    case 'ual':
      return {
        ...state,
        ual: action.ual
      }

    case 'data':
      return {
        ...state,
        data: action.data
      }

    case 'setUrlParams':
      return {
        ...state,
        urlParams: action.params
      }

    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

export const SharedStateProvider = ({ children, ual, ...props }) => {
  const [state, dispatch] = React.useReducer(sharedStateReducer, {
    ...initialValue
  })
  const value = React.useMemo(() => [state, dispatch], [state])

  useEffect(() => {
    const load = async () => {
      await wax.isAutoLoginAvailable()

      if (!wax.userAccount) return

      const regUser = await getUserInfo(wax.userAccount, true)

      dispatch({
        type: 'setUser',
        user: { accountName: wax.userAccount, useWax: true, ...regUser }
      })
    }

    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      dispatch({ type: 'ual', ual })

      if (!ual.activeUser) return

      const regUser = await getUserInfo(ual.activeUser.accountName, false)

      dispatch({
        type: 'setUser',
        user: {
          accountName: ual.activeUser.accountName,
          useWax: false,
          ...regUser
        }
      })
    }

    load()
  }, [ual?.activeUser])

  return (
    <SharedStateContext.Provider value={value} {...props}>
      {children}
    </SharedStateContext.Provider>
  )
}

SharedStateProvider.propTypes = {
  children: PropTypes.node,
  ual: PropTypes.object
}

export const useSharedState = () => {
  const context = React.useContext(SharedStateContext)

  if (!context) {
    throw new Error(`useSharedState must be used within a SharedStateContext`)
  }

  const [state, dispatch] = context
  const setState = payload => dispatch({ type: 'set', payload })
  const showMessage = payload => dispatch({ type: 'showMessage', payload })
  const hideMessage = () => dispatch({ type: 'hideMessage' })
  const login = async () => {
    const accountName = await wax.login()
    const regUser = await getUserInfo(accountName, true)

    dispatch({ type: 'set', payload: { user: { ...regUser, accountName } } })
  }
  const logout = () => {
    dispatch({ type: 'set', payload: { user: null } })
    delete wax.userAccount
  }
  const setUser = async user => {
    const regUser = await getUserInfo(user.accountName, user.useWax)

    dispatch({ type: 'set', payload: { user: { ...user, ...regUser } } })
  }
  const loginBothWallet = async () => {
    dispatch({ type: 'set', payload: { showLoginModal: true } })
  }
  const cancelLogin = async () => {
    dispatch({ type: 'set', payload: { showLoginModal: false } })
  }
  const logoutBothWallet = () => {
    dispatch({ type: 'setUser', user: null })
    delete wax.userAccount
    state.ual.logout()
  }
  const cloudWalletLogin = async () => {
    const accountName = await wax.login()
    const regUser = await getUserInfo(accountName, true)

    dispatch({
      type: 'setUser',
      user: { accountName, useWax: true, ...regUser }
    })
  }
  const ualLogin = () => {
    state.ual.showModal()
  }
  const setShowHeader = showHeader => {
    dispatch({ type: 'showHeader', showHeader })
  }
  const setData = data => {
    dispatch({ type: 'data', data })
  }
  const setUrlParams = params => {
    dispatch({ type: 'setUrlParams', params })
  }
  const updatePackAmount = (id, type) => {
    const packFiltered = (state.user?.packs[type] || []).filter(
      assetId => assetId !== id
    )

    dispatch({
      type: 'setUser',
      user: {
        ...state.user,
        packs: { ...state.user.packs, [type]: packFiltered }
      }
    })
  }

  return [
    state,
    {
      setState,
      showMessage,
      hideMessage,
      login,
      logout,
      setUser,
      loginBothWallet,
      cancelLogin,
      logoutBothWallet,
      cloudWalletLogin,
      ualLogin,
      setShowHeader,
      setData,
      setUrlParams,
      updatePackAmount
    }
  ]
}
