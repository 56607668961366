import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { makeStyles } from '@mui/styles'

import styles from './styles'

const useStyles = makeStyles(styles)

const Footer = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={classes.footerBox}>
      <div className={classes.footerBoxOpacity}>
        <span className={classes.footerText}>{t('joinCommunity')}</span>
        <div className={classes.gridRow}>
          <a
            href="https://dappradar.com/wax/games/kolobok-adventures"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            DAPPRADAR
          </a>
          <a
            href="https://t.me/ilovekolobok"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            TELEGRAM
          </a>
          <a
            href="http://bit.ly/kolobok_discord"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            DISCORD
          </a>
          <a
            href="https://twitter.com/kolobok_io"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            TWITTER
          </a>
          <a
            href="https://www.facebook.com/Kolobokio-106751941563022"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            FACEBOOK
          </a>
          <a
            href="https://kolobok-io.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            MEDIUM
          </a>
          <a
            href="https://www.instagram.com/kolobok.io/"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            INSTAGRAM
          </a>
          <a
            href="mailto:play@kolobok.io?subject=I%20Love%20Kolobok!&body=Hello%20Kolobok%20Developers!"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            EMAIL
          </a>
          <a
            href="https://github.com/CryptoLions/KolobokGame-Contract"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.btnSmall, classes.gridItem, classes.extra)}
          >
            GITHUB
          </a>
        </div>
        <span className={classes.canPlay}>
          {`${t('canPlay')} `}
          <a
            href="https://eos.kolobok.io"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkLine}
          >
            EOS
          </a>
          {` ${t('and')} `}
          <a
            href="https://telos.kolobok.io"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkLine}
          >
            TELOS
          </a>
        </span>
        <span className={classes.copyRight}>
          {`© 2019-${new Date().getFullYear()} `}
          <a
            href="https://cryptolions.io"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkLine}
          >
            CryptoLions
          </a>
          {`. ${t('allRights')}`}
        </span>
        <span className={classes.footerLegend}>{t('footerLegend')}</span>
      </div>
    </div>
  )
}

export default memo(Footer)
