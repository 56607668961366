import card1 from 'assets/pack1.png'
import card2 from 'assets/pack2.png'
import defaultCard from 'assets/default-card.png'

export const cardImg = {
  regular: card2,
  mega: card1,
  defaultCard
}

export default cardImg
