export const requestOptions = (source, utmSource, referral, email) => {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      fields: { source, utm_source: utmSource, referral }
    })
  }
}
