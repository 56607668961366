export default theme => ({
  footerBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)'
  },
  footerBoxOpacity: {
    backgroundColor: '#0000009e',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16
  },
  footerText: {
    flexGrow: '0',
    margin: '43px 0 36px !important',
    fontSize: '27px !important',
    fontWeight: '500 !important',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11 !important',
    letterSpacing: '1.62px !important',
    textAlign: 'center',
    color: '#fff'
  },
  gridRow: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    width: '100%'
  },
  btnSmall: {
    height: '32px',
    flexGrow: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '100px',
    border: 'solid 1px #d8dad1',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.29',
    letterSpacing: '0.84px',
    textAlign: 'center',
    color: '#d8dad1',
    backgroundColor: 'transparent',
    margin: '8px',
    textDecoration: 'none',
    padding: '0 8px'
  },
  gridItem: {
    flexBasis: '28%',
    '-ms-flex': 'auto',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2)
    }
  },
  extra: {
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      flexBasis: 'auto'
    }
  },
  canPlay: {
    width: '100%',
    flexGrow: '0',
    margin: '60px 0 !important',
    fontSize: '27px !important',
    fontWeight: 'normal !important',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11 !important',
    letterSpacing: '1.62px !important',
    textAlign: 'center',
    color: '#fff'
  },
  linkLine: {
    fontStyle: 'normal',
    textDecoration: 'underline',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.0357143em',
    color: '#ffffff'
  },
  footerLegend: {
    width: '100%',
    flexGrow: '0',
    marginTop: '19px !important',
    fontSize: '14px !important',
    fontWeight: '300 !important',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal !important',
    letterSpacing: '0.84px !important',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.5)',
    maxWidth: 1225,
    marginBottom: 80
  },
  copyRight: {
    width: '100%',
    flexGrow: '0',
    fontSize: '14px !important',
    fontWeight: '300 !important',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal !important',
    letterSpacing: '0.84px !important',
    textAlign: 'center',
    color: '#fff'
  }
})
