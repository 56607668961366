export const formatResource = (value, precision, cpu = false) => {
  const resource = cpu ? value * 0.001 : value / 1024

  if (!value || isNaN(value)) return value

  let newValue = parseFloat(resource)

  if (precision >= 0) {
    newValue = newValue.toFixed(precision)
  }

  return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
