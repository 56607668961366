export default theme => ({
  appBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.6) !important',
    boxShadow: 'none !important',
    borderBottom: 0
  },
  notVisibleHeader: {
    backgroundColor: 'transparent !important'
  },
  toolbar: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(0, 2)}`
    }
  },
  typography: {
    color: `${theme.palette.text.primary}`,
    flexGrow: 1
  },
  desktopSection: {
    display: 'flex',
    height: 54,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  roundedBtn: {
    borderRadius: '30px !important',
    borderColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.white} !important`
  },
  language: {
    color: `${theme.palette.common.white} !important`,
    '& .languageLabel': {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      '& .languageLabel': {
        display: 'flex'
      }
    }
  },
  menuLanguage: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`
  },
  menuUser: {
    backgroundColor: `${theme.palette.common.black} !important`
  },
  userTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 10,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: '#D4B165',
    margin: theme.spacing(2, 0, 1, 0)
  },
  userSpan: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '0.4px',
    color: '#FFFFFF'
  },
  strongRight: {
    marginRight: theme.spacing(1)
  },
  strongLeft: {
    marginLeft: theme.spacing(1)
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    '& div': {
      color: theme.palette.common.white,
      margin: '5px 0',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  logoutBtn: {
    borderRadius: '30px !important',
    borderColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.white} !important`,
    marginTop: `${theme.spacing(2)} !important`
  },
  headerLogoK2: {
    height: 55,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginTop: theme.spacing(1)
    }
  },
  displayNone: {
    display: 'none !important'
  }
})
