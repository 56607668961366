export default {
  primary: {
    main: '#CC2D75'
  },
  secondary: {
    main: '#93ACB9'
  },
  info: {
    main: '#424242'
  },
  text: {
    secondary: '#ffffff'
  }
}
